import { Col, DatePicker, Form } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { getCommunicationAvailability } from "../../../Redux/OffersSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Wrapper } from "../../Deals/WeekPicker/pickerCss";
import updateLocale from "dayjs/plugin/updateLocale";


dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
    weekStart: 1,
});

const CustomDatePicker = (props) => {
    const dispatch = useDispatch();

    const [disabledDates, setDisabledDates] = useState([]);
    const [targetWeekNumber, setTargetWeekNumber] = useState([])

    let nextWeek;

    const date = new Date()
    const getDayNumb = date.getDay();
    if (getDayNumb >= 3 || getDayNumb === 0) {
        nextWeek = date.setDate(date.getDate() + 4);
    }

    let { communicatoinAvailability } = useSelector((state) => state?.offers) || [];

    useEffect(() => {
        if (props.restaurantId) {
            getCommunicationAvailabilityAPI(dayjs().format('MM'), dayjs().format('YYYY'), props.restaurantId)
        }
    }, [props.restaurantId])

    const getCommunicationAvailabilityAPI = (currentMonth, currentYear, restaurantId) => {
        if (restaurantId) {
            dispatch(getCommunicationAvailability({ currentMonth, currentYear, restaurantId }))
        }
    }
    useEffect(() => {
        if (communicatoinAvailability && communicatoinAvailability.length > 0) {
            setDisabledDates(Array.from(new Set(communicatoinAvailability?.map(i =>
                i.toDate
            )?.flat())))
        } else {
            setDisabledDates([])
        }
    }, [communicatoinAvailability])



    useEffect(() => {
        if (disabledDates && disabledDates.length > 0) {
            setTargetWeekNumber([calculateWeekNumber(moment(nextWeek)), ...Array.from(new Set(disabledDates.map(date => {
                return calculateWeekNumber(moment(date, 'DD'))
            })))])
        } else {
            setTargetWeekNumber(calculateWeekNumber(moment(nextWeek)))
        }
    }, [disabledDates])

    const calculateWeekNumber = (date) => {
        return `${dayjs(date).week()}`;
    };
    const disabledDate = (current) => {

        if (disabledDates.find(i => dayjs(i).startOf('week').$d <= current.$d && dayjs(i).endOf('week').$d >= current.$d)) {
            return true;
        }
        else if (dayjs().startOf('week').$d <= current.$d && dayjs().endOf('week').$d >= current.$d || dayjs().startOf('week').$d > current.$d) {
            return true;
        }
        else if (dayjs().startOf('week').$d <= current.$d && dayjs().endOf('week').$d >= current.$d) {
            return true;
        }
        else if (dayjs(nextWeek).startOf('week').$d <= current.$d && dayjs(nextWeek).endOf('week').$d >= current.$d) {
            return true
        }
        else if (current.year() === dayjs().year()) {
            return false;
        }
        else {
            return false;
        }
    };

    const dateRender = (current) => {
        const isDisabled = disabledDates.find(i => dayjs(i).week() === current.week());

        if (isDisabled) {
            return (
                <div style={{ display: "inline-block" }}>
                    {current.date()}
                    <div className="errortolltip">
                        <p>
                            This Date cannot be selected because it is outside of the review
                            period, please select a date further away. Unavailable dates are
                            shown with a gray background.
                        </p>
                    </div>
                </div>
            );
        }

        return <div className="ant-picker-cell-inner">{current.date()}</div>
    };
    return (
        <>
            <Col  {...props.col}>
                <Wrapper>

                    <Form.Item
                        name={props.name}
                        label={props.label}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValue={props.value}
                        className="form-group"
                        rules={props.rules}
                    >
                        <DatePicker
                            dateRender={dateRender}
                            disabledDate={disabledDate}
                            value={props.value}
                            format={props.format}
                            name={props.name}
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            onChange={props.onChange}
                            allowClear={false}
                            placeholder={props.placeholder}
                            onPanelChange={(value, mode) => {
                                getCommunicationAvailabilityAPI(value.format('MM'), value.format('YYYY'), props.restaurantId)
                            }}
                            // disabledDate={(current) => {
                            //     let customDate = moment().format(props.format);
                            //     return current && current < moment(customDate, props.format);
                            // }}
                            onWheel={props.onWheel}
                            disabled={props.disabled}
                        />
                    </Form.Item>
                </Wrapper>
            </Col>
        </>
    )
}
export default CustomDatePicker;